/* Light */
@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-Light-WebXL.woff2') format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-Light-WebXL.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-LightItalic-WebXL.woff2')
      format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-LightItalic-WebXL.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* Regular */
@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-Regular-WebXL.woff2') format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-Regular-WebXL.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-RegularItalic-WebXL.woff2')
      format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-RegularItalic-WebXL.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Medium */
@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-Medium-WebXL.woff2') format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-Medium-WebXL.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-MediumItalic-WebXL.woff2')
      format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-MediumItalic-WebXL.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/* Semibold */
@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-SemiBold-WebXL.woff2') format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-SemiBold-WebXL.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-SemiBoldItalic-WebXL.woff2')
      format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-SemiBoldItalic-WebXL.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* Bold */
@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-Bold-WebXL.woff2') format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-Bold-WebXL.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse_intl/SuisseIntl-BoldItalic-WebXL.woff2') format('woff2'),
    url('../fonts/suisse_intl/SuisseIntl-BoldItalic-WebXL.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
