@import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";

/* If you need to add @import statements, do so up here */
@import "fonts.css";

@import "jit-refresh.css";
/* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-z0: hsla(0, 0%, 97%, 1);
    --color-z1: hsla(0, 0%, 92%, 1);

    --color-fg: rgba(0, 0, 0, 1);
    --color-fg-secondary: rgba(0, 0, 0, 0.6);
    --color-fg-tertiary: rgba(0, 0, 0, 0.2);
    --color-fg-inverted: rgba(255, 255, 255, 1);

    --color-primary: rgba(218, 20, 56, 1);
    --color-primary-secondary: rgba(218, 20, 56, 0.6);
    --color-primary-tertiary: rgba(218, 20, 56, 0.2);
  }

  :root[data-theme="dark"] {
    --color-z0: rgba(0, 0, 0, 1);
    --color-z1: rgba(10, 10, 10, 1);

    --color-fg: rgba(255, 255, 255, 1);
    --color-fg-secondary: rgba(255, 255, 255, 0.6);
    --color-fg-tertiary: rgba(255, 255, 255, 0.25);
    --color-fg-inverted: rgba(0, 0, 0, 1);

    --color-primary: rgba(255, 38, 77, 1);
    --color-primary-secondary: rgba(255, 38, 77, 0.6);
    --color-primary-tertiary: rgba(255, 38, 77, 0.2);
  }

  em {
    font-style: normal;
    color: theme("colors.primary.DEFAULT");
  }

  turbo-frame {
    display: contents;
  }
}

@layer components {

  a-section,
  .a-section {
    display: block;
    margin: theme("spacing.16") theme("spacing.9");

    @media screen(lg) {
      margin: theme("spacing.16") 0;
    }
  }

  a-grid,
  .a-grid {
    max-width: theme("screens.2xl");
    margin: 0 auto;

    @media screen(lg) {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: theme("spacing.6");
    }
  }

  /* Icons */
  .material-symbols-outlined {
    font-variation-settings:
      "FILL" 1,
      "wght" 300,
      "GRAD" -25,
      "opsz" 48;
    user-select: none;
  }

  .material-symbols-outlined.icon-outline {
    font-variation-settings:
      "FILL" 0,
      "wght" 300,
      "GRAD" -25,
      "opsz" 48;
  }

  .material-symbols-outlined.icon-fill {
    font-variation-settings:
      "FILL" 1,
      "wght" 300,
      "GRAD" -25,
      "opsz" 48;
  }
}

@layer utilities {
  .scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }

  .hidden-details>summary {
    list-style: none;
  }

  .hidden-details>summary::-webkit-details-marker {
    display: none;
  }

  /* Icons */
  .icon-sm {
    font-size: 20px;
  }

  .icon-base {
    font-size: 24px;
  }

  .icon-2base {
    font-size: 28px;
  }

  .icon-md {
    font-size: 32px;
  }

  .icon-5xl {
    font-size: 48px;
  }

  .icon-6xl {
    font-size: 60px;
  }

  .icon-9xl {
    font-size: 128px;
  }

  .animate-zoom {
    animation: zoom-in 7s linear;
  }

  @keyframes zoom-in {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(1.2);
    }
  }
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.openseadragon-canvas:focus {
  outline: none;
}

/* ProseMirror */

.ProseMirror h3 {
  font-weight: theme("fontWeight.medium");
  margin-bottom: theme("spacing.6");
}

.ProseMirror *+h3 {
  margin-top: theme("spacing.16");
}

.ProseMirror h4 {
  margin-bottom: theme("spacing.6");
}

.ProseMirror *+h4 {
  margin-top: theme("spacing.6");
}

.ProseMirror p+p {
  margin-top: theme("spacing.4");
}

.ProseMirror strong {
  font-weight: theme("fontWeight.medium");
}

.ProseMirror em {
  font-style: italic;
  color: inherit;
}

.ProseMirror ul,
ol {
  margin: theme("spacing.4") 0;
  padding-left: theme("spacing.6");

  li::marker {
    color: theme("colors.primary.DEFAULT");
  }
}

.ProseMirror ul {
  list-style-type: disc;
}

.ProseMirror ol {
  list-style-type: decimal;
}

.ProseMirror table {
  width: 100%;
}

.ProseMirror table thead {
  vertical-align: top;
}

.ProseMirror table thead tr {
  position: sticky;
  top: 0;
  background-color: theme("colors.z0");
  color: theme("colors.primary.DEFAULT");
  text-align: left;
}

.ProseMirror table thead th {
  font-weight: normal;
  padding: theme("spacing.2") 0;
  border-bottom: solid theme("colors.fg.tertiary") 1px;
}

.ProseMirror table tbody {
  vertical-align: top;
}

.ProseMirror table tbody tr {
  width: 100%;
}

.ProseMirror table tbody td {
  padding: theme("spacing.2") 0;
  border-bottom: solid theme("colors.fg.tertiary") 1px;
}
